import { type Component } from "solid-js";
import { Collapsible } from "~/components/ui";

export const BuiltFrameCareInstr: Component = () => {
  return (
    <Collapsible
      name="care-instructions"
      trigger="Care Instructions"
      triggerClass="font-bold"
      contentClass="text-roma-dark-grey text-sm child:pb-3 leading-7"
    >
      <p class="my-4">
        Gently wipe your frame with a soft dry cloth. To remove spots or smudges
        from acrylic or glass, we recommend applying glass cleaner to a clean,
        soft, dry cloth or paper towel and then to the surface so that the
        cleaner does not settle on the frame, then buff the surface dry to
        remove any streaks.
      </p>
    </Collapsible>
  );
};
